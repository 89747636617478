import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import HeaderImg from "../../img/siteheaders/gutachten.png";

class Gutachten extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Gutachten | Ingenieurbüro Jerratsch</title>
                </Helmet>


                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Gutachten"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop">Begutachten in diversen Bereichen</th></tr>                        
                        <tr><td>Begutachtung von Unternehmensabläufen</td></tr>
                        <tr><td>Durchführung von Analysen einzelner Unternehmensprozesse und deren Optimierung</td></tr>
                        <tr><td>Unterstützende Beratung bei der Unternehmensoptimierung</td></tr>
                        <tr><td>Durchführung von internen Qualitäts- , Umwelt- , Energie- , Arbeitssicherheits- , und Lieferantenaudits</td></tr>
                        <tr><td>Unterstützung bei Managementreviews</td></tr>
                        <tr><td>Analyse von Gewährleistungsbearbeitungen</td></tr>
                    </tbody>
                </table>

            </React.Fragment>
        )

    }

}

export default Gutachten;
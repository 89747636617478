import React from "react";
import styled from "styled-components";

import LogoImage from "../../img/Logo.png";

class Header extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            smaller: false
        };

        this.handleScroll = this.handleScroll.bind(this);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleScroll);
    }

    componentDidMount(){
        this.handleScroll();
    }

    handleScroll(){
        const distanceY = window.pageYOffset || document.documentElement.scrollTop;
        const shrinkOn = 80;
        if (distanceY > shrinkOn) {
            this.setState({
                smaller: true
            });
        } else {
            this.setState({
                smaller: false
            });
        }
    }

    render(){

        return (
            <header className={`${this.state.smaller ? "smaller" : ""}`}>
                <div className="header">
                    <img src={LogoImage} style={{position: "relative", left: "-300px", top: "-25px"}}/>
                    <h1 style={{position: "relative", top: "-185px"}}>Ingenieurbüro Jerratsch</h1>
                    <h2 style={{position: "relative", top: "-176px"}}>Beratung. Begutachtungen. Audits.</h2>
                    <h2 style={{position: "relative", top: "-165px"}}>- Qualität seit 1989 -</h2>
                    <nav>
                        <ul>
                            <li><a href="/">Startseite</a></li>
                            <li><a href="/aktuelles">Aktuelles</a></li>
                            <li><a href="/unternehmen">Unternehmen</a></li>
                            <li><a href="/geschaeftsfelder">Geschäftsfelder</a></li>
                            <li><a href="/ueberuns">Über uns</a></li>
                            <li><a href="/referenzen">Referenzen</a></li>
                            <li style={{width:"200px"}}><a href="/impressum">Impressum / Kontakt</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );

    }


}




export default Header;
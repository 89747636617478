import React from "react";
import {Helmet} from "react-helmet";

import HeaderImg from "../../img/siteheaders/start.png";
import HeaderCard from "../../components/HeaderCard";

class Page extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Unternehmensgeschichte | Ingenieurbüro Jerratsch</title>
                </Helmet>
                <div className="mainWrapper center">    
                    <div className="maincard standAlone">                	                       			
                        <div className="content">
                            <HeaderCard img={HeaderImg} text={"Unternehmen"} />
                            
                            <div className="widecard">    
                                <p className="cardHeading">Wie alles begann...</p>
                                <div style={{height: "400px", marginBottom: "10px"}}>
                                    <p className="ing_info">
                                        Das Unternehmen wurde 1989 unter der Bezeichnung Ing. Büro Jerratsch gegründet. <br />
                                        In den Anfangsjahren wurden vorwiegend statische Berechnungen für Wohnungs- und Industriebau erstellt, sowie
                                        architektonische Planungsaufgaben im Wohnungsbau übernommen.
                                        <br/><br/>
                                        Seit 1995 werden Begutachtungen von QM-Systemen und seit 1997 von Sicherheitsmanagementsystemen, sowie seit 2011
                                        von Umweltmanagement- und anderen Systemen durchgeführt.
                                        <br/>
                                        Nach entsprechender Ausbildung und Erfahrung wurde der Bereich der Arbeitssicherheit allgemein, des Datenschutzes,
                                        des Umweltschutztes sowie die Beratung des Managements mit aufgenommen.
                                        <br/><br/>
                                        Ab 2002 wude der Bereich der Beratung systematisch aufgebaut und auf den heutigen breit gefächerten Stand erweitert.
                                    </p>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                    
                </div>
            </React.Fragment>
        )

    }

}

export default Page;
import React from "react";
import {Helmet} from "react-helmet";
import LinkCard from "../../components/LinkCard";

import HeaderImg from "../../img/siteheaders/start.png";
import HeaderCard from "../../components/HeaderCard";

class Home extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Ingenieurbüro Jerratsch</title>
                </Helmet>
                <div className="mainWrapper center">
                    <div className="maincard standAlone">                	                       			
                        <div className="content">
                            <HeaderCard img={HeaderImg} text={"Hier sind Sie richtig."} />                            
                                <p>
                                    Sie kommen aus dem Bereich der Bauindustrie? Dann sind Sie hier genau richtig! <br />
                                    Wir bieten Ihnen Beratung, Betreuung, Begutachtungen und Auditierungen. 
                                    Außerdem unterstützen wir Sie beim Aufbau und Integration von Managementsystemen aus einer konkurenzlos großen Auswahl.
                                    <br /> <br />
                                    Klicken Sie sich durch unsere Webpräsenz und nehmen Sie Kontakt mit uns auf, wenn Sie Fragen haben oder ein Angebot anfragen wollen. <br />
                                    Wir freuen uns auf Sie!
                                </p>
                                
                                <br />
                                
                                <ul>
                                    <LinkCard link={"/unternehmen"} text={"Unsere Unternehmensgeschichte"} withArrow={true} />
                                    <LinkCard link={"/ueberuns"} text={"Über uns"} withArrow={true} />
                                    <LinkCard link={"/geschaeftsfelder"} text={"Unsere Kompetenzen"} withArrow={true} />
                                    <LinkCard link={"/referenzen"} text={"Referenzen die für sich sprechen"} withArrow={true} />
                                    <LinkCard link={"/impressum"} text={"Nehmen Sie Kontakt auf"} withArrow={true} />
                                </ul>
                        </div>                                   
                    </div>
                    
                </div>
            </React.Fragment>
        )

    }

}

export default Home;
import React from "react";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import HeaderImg from "../../img/siteheaders/Management.png";
import Helmet from "react-helmet";

class Managementsysteme extends React.Component{

    constructor(props){
        super(props);
    }

    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Managementsysteme | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Managementsysteme"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop">Unser Leistungsspektrum im Gebiet Managementsysteme</th></tr>  
                        
                        <tr><td>Erstellung einer firmenspezifischen prozessorientierten Managementdokumentation nach den jeweils benötigten Regelwerken</td></tr>
                        <tr><td>Betreuung eines installierten Managementsystems in den jeweils unten aufgeführten Bereichen</td></tr>
                        <tr><td>Vorbereitung auf externe Zertifizierungs- oder Überwachungsaudits
    Mitarbeiterschulung für das jeweilige Managementsystem</td></tr>
                        <tr><td>Beratung bei der Erstellung von Verfahrensanweisungen gemäß den vorhandenen Unternehmensprozessen</td></tr>                        
                        <tr><td>Nachunternehmer-/Lieferantenbetreuung bzw. Nachunternehmer-/Lieferantenaudits</td></tr>
                        <tr><td>Gutachten zu Konformität bezüglich weiterer Regelwerke z.B. DIN 77200; DIN 15906</td></tr>  
                        
                        <tr><th className="umwelt"><a id="Umwelt">Umweltschutz</a></th></tr>
                        <tr><td>Beratung und Unterstützung beim Aufbau oder Integration eines Umweltschutzmanagementsystems nach ISO 14001</td></tr>                        
                        <tr><td>Beratung zum Thema Umweltbaubegleitung nach den Vorgaben der Deutschen Bahn</td></tr>                        

                        <tr><th className="energie"><a id="Energie">Energiemanagement</a></th></tr>
                        <tr><td>Beratung und Unterstützung beim Aufbau oder Integration eines Energiemanagementsystems nach ISO 50001</td></tr>

                        <tr><th className="arbsich"><a id="Arbeitssicherheit">Arbeitssicherheit</a></th></tr>
                        <tr><td>Beratung und Unterstützung zur Einführung und Aufrechterhaltung eines Managementsystem nach:</td></tr>
                        <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;SCC / SCP (Sicherheits Certifikat Contractoren)</td></tr>
                        <tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;ISO 45001  <br /></td></tr>
                        <tr><th className="quali"><a id="QM">Qualitätsmanagement</a></th></tr>
                        <tr><td>Beratung und Unterstützung beim Aufbau eines Qualitätsmanagementsystems nach DIN EN ISO 9001</td></tr>
                        
                        <tr><th className="exservice"><a id="ExcelentService">Excelent Service</a></th></tr>                      
                        <tr><td>geprüfter Fachberater für Dienstleistungs-Excellence nach DIN SPEC 77224</td></tr>
                    </tbody>
                </table>

            </React.Fragment>
        )

    }

}

export default Managementsysteme;
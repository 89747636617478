import React from "react";
import styled from "styled-components";

const Card = styled.div.attrs({className:"siteHeader"})`
    background-image: url(${props => props.img});
`;

class HeaderCard extends React.Component{

    constructor(props){
        super(props);
    }


    render(){

        return (
            <Card img={this.props.img}>
                <h1 className="siteHeader">{this.props.text}</h1>
            </Card>
        );

    }

}

export default HeaderCard;
import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import ImageGallery from "react-image-gallery";
import HeaderImg from "../../img/siteheaders/datenschutz.png";

import MerklingenImg1 from "./images/merklingen1.jpg";
import MerklingenImg2 from "./images/merklingen2.jpg";
import MerklingenImg3 from "./images/merklingen3.jpg";

import MerklingenPDF from "./images/Arge_Merklingen.pdf";

import WeilheimImg1 from "./images/weilheim1.jpg";
import WeilheimPDF from "./images/Arge_Weilheim.pdf";

const refimgs_merklingen = [
    {
        original: MerklingenImg1,
        thumbnail: MerklingenImg1,
    },
    {
        original: MerklingenImg2,
        thumbnail: MerklingenImg2,
    },
    {
        original: MerklingenImg3,
        thumbnail: MerklingenImg3,
    },
];
const refimgs_weilheim = [
    {
        original: WeilheimImg1,
        thumbnail: WeilheimImg1,
    }
];

class Rechtssicher extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Großprojekte | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Arbeitssicherheit bei Großprojekten"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop datenschutz">Rechtssichere Arbeitssicherheit bei Großprojekten</th></tr>                        
                        <tr><td>Beratung zur Notfallplanung und Notfallübungen</td></tr>
                        <tr><td>Schulungen / Unterweisungen der Mitarbeiter zu baustellenspezifischen Themen</td></tr>
                        <tr><td>Sicherheitsbegehungen der Großbaustellen</td></tr>
                    </tbody>
                </table>

                <div className="widecard" style={{
                    width: "80%",
                }}>
                    <p className="cardHeading" style={{
                        textAlign: "center"
                    }}>
                        Bahnprojekt Neubaustrecke Stuttgart-Ulm Alphochfläche (Merklingen)
                    </p>
                    <div style={{
                        padding: "10px"
                    }}>
                        <ImageGallery items={refimgs_merklingen} showPlayButton={false}/>
                    </div>
                </div>

                <div style={{
                    padding: "20px",
                    marginBottom: "50px"
                }}>
                    <p style={{
                        textAlign: "center"
                    }}>
                        <a href={MerklingenPDF} target="_blank">
                            Referenzschreiben der ARGE NBS Merklingen (PDF)
                        </a>
                    </p>
                </div>

                <br/>

                <div className="widecard" style={{
                    width: "80%",
                }}>
                    <p className="cardHeading" style={{
                        textAlign: "center"
                    }}>
                        Bahnprojekt Neubaustrecke Stuttgart-Ulm Streckenabschnitt Weilheim an der Teck
                    </p>
                    <div style={{
                        padding: "10px"
                    }}>
                        <ImageGallery items={refimgs_weilheim} showPlayButton={false}/>
                    </div>
                </div>

                <div style={{
                    padding: "20px",
                    marginBottom: "50px"
                }}>
                    <p style={{
                        textAlign: "center"
                    }}>
                        <a href={WeilheimPDF} target="_blank">
                            Referenzschreiben der ARGE NBS Weilheim an der Teck (PDF)
                        </a>
                    </p>
                </div>

            </React.Fragment>
        )

    }

}

export default Rechtssicher;
import React from "react";

import HeaderCard from "../../components/HeaderCard";
import HeaderImg from "../../img/siteheaders/ref.png";
import Helmet from "react-helmet";


class Start extends React.Component{

    constructor(props){
        super(props);
    }

    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Referenzen | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <div className="content">

                    <HeaderCard img={HeaderImg} text={"Referenzen"} />

                    <div className="widebox">
                        <h2>Ein breit gefächertes Erfahrungsspektrum, das Ihnen zu gute kommt.</h2>
                        <p>Sie finden hier eine Übersicht über unsere Tätigkeitsbereiche und gelangen über das Menü am linken Rand zu einer Auflistung der namenhaften Firmen, die schon von unseren Leistungen überzeugt sind.
                        Überzeugen Sie sich nun selbst. So viele Kunden können nicht irren!</p>
                        
                        <table id="refUbersicht">
                            <tbody>
                                <tr><th className="tableTop">Erfahrung in den Bereichen</th></tr>                        
                                <tr><th>der Bauindustrie mit den Sparten</th></tr>
                                <tr><td>Hochbau</td></tr>
                                <tr><td>Schlüsselfertigbau</td></tr>
                                <tr><td>Ingenieurbau</td></tr>
                                <tr><td>Tiefbau</td></tr>
                                <tr><td>Spezialtiefbau</td></tr>
                                <tr><td>Tunnelbau</td></tr>
                                <tr><td>Strassenbau</td></tr>
                                <tr><td>Gleisbau</td></tr>
                                <tr><td>Tankstellenbau</td></tr>
                                <tr><td>Stahlbau</td></tr>
                                <tr><td>Rohrleitungsbau</td></tr>
                                <tr><td>Innenausbau</td></tr>
                                <tr><td>Kraftwerksbau</td></tr>

                                <tr><th>der Ingenieur- / Architekturbüros mit den Gebieten</th></tr>
                                <tr><td>Projektsteuerung</td></tr>
                                <tr><td>Wasserbau</td></tr>
                                <tr><td>Bauwerksanierung</td></tr>
                                <tr><td>Umweltschutz</td></tr>
                                <tr><td>Ingenieurbau</td></tr>
                                <tr><td>Entwurfs- , Genehmigungs- und Ausführungsplanung</td></tr>

                                <tr><th>der Energietechnik mit den Gebieten </th></tr>
                                <tr><td>Gasaufbereitung</td></tr>
                                <tr><td>Tankstellentechnik u. -service</td></tr>

                                <tr><th>der Elektrotechnik mit </th></tr>
                                <tr><td>Schaltanlagenbau</td></tr>                        

                                <tr><th>der Isolierbranche mit den Sparten </th></tr>
                                <tr><td>Haustechnik</td></tr>
                                <tr><td>Industriebau</td></tr>
                                <tr><td>Schiffsbau</td></tr>
                                <tr><td>Hochtemperaturisolierung</td></tr>
                                <tr><td>Fassadenbau</td></tr>
                                <tr><td>Industrieschallschutz</td></tr>

                                <tr><th>der Dienstleistung mit den Sparten </th></tr>
                                <tr><td>Reinigung</td></tr>
                                <tr><td>Industriereinigung</td></tr>
                                <tr><td>Personaldienstleistung</td></tr>
                                <tr><td>Sicherheitsdienstleistungen</td></tr>

                                <tr><th>Als Datenschutz- und Umweltschutzbeauftragter</th></tr>                        

                                <tr><th>Auslandseinsätze in </th></tr>
                                <tr><td>Chile</td></tr>
                                <tr><td>Italien</td></tr>
                                <tr><td>Irland</td></tr>
                                <tr><td>England</td></tr>
                                <tr><td>Niederlande</td></tr>
                                <tr><td>Norwegen</td></tr>
                                <tr><td>Schweden</td></tr>
                                <tr><td>Dänemark</td></tr>
                                <tr><td>Mexiko</td></tr>
                                <tr><td>Polen</td></tr>
                                <tr><td>Tschechien</td></tr>
                                <tr><td>Malaysia</td></tr>
                                <tr><td>Schweiz</td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </React.Fragment>


        );

    }

}

export default Start;
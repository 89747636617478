import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import ArrowImg from "../../img/arrow.png";

const SubLink = ({link, text, withArrow, children, ...props}) => {
    return (
        <Link to={link}>
            {
                withArrow ?
                <React.Fragment>
                    <li>
                        {children}
                        <img src={ArrowImg}/>
                    </li>
                </React.Fragment>
                :
                children
            }
        </Link>
    )
};

class SubMenu extends React.Component{

    constructor(props){
        super(props);
    }


    render(){

        return (
            <div className="subMenu">
                <ul>
                    <SubLink link={"/referenzen/beratung"} withArrow={true}>
                        Beratung / Betreuung
                    </SubLink>
                    <SubLink link={"/referenzen/begutachtungen"} withArrow={true}>
                        Begutachtungen
                    </SubLink>
                </ul>
            </div>
        );

    }

}

export default SubMenu;
import React from "react";

import HeaderImg from "../../img/siteheaders/uberUns.png";
import HeaderCard from "../../components/HeaderCard";

import DJPic from "../../img/Detlef_Jerratsch.jpg";
import Helmet from "react-helmet";

class Ueberuns extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Über uns | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <div className="mainWrapper center">
                    <div className="maincard standAlone">                	                       			
                        <div className="content">
                            <HeaderCard img={HeaderImg} text={"Über uns"} />                            
                                
                            <div className="widecard">    
                                <p className="cardHeading">Detlef Jerratsch</p>
                                    <div style={{height:"200px", paddingBottom: "30px"}}>
                                        <p className="info">Studium konstruktives Bauingenieurwesen an der RWTH Aachen. <br /> Konstruktion und Berechnung von Hochbauten aller Art incl.
                                            <br /> - Bereich Denkmalpflege
                                            <br /> - Betriebsleiter Betonfertigteilwerk für Hoch- / Tiefbau
                                            <br /> - Assistent beim Vorstand für Bereich Managementsysteme
                                            <br /> - Prokurist im Bereich Qualität, Sicherheit, Umwelt und Datenschutz
                                            <br /> - Auditor einer Zertifizierungsgesellschaft
                                            <br />                        
                                            <br /> Seit 2002 selbständiges Ingenieurbüro.</p>
                                        
                                        <img className="pic" src={DJPic} />
                                    </div>
                                    <div style={{paddingRight: "115px"}}>
                                            <div className="box">
                                                <p className="cardSubHeading">&nbsp;&nbsp;&nbsp;&nbsp;Zuständigkeiten</p>
                                                
                                                    <ul id="zustandigkeiten">
                                                        <li>Managementsysteme</li>
                                                        <li>Arbeitssicherheit</li>
                                                        <li>Datenschutz</li>
                                                        <li>Managementberatung</li>
                                                    </ul>
                                                
                                                <br />
                                            </div>
                                            <div className="box">
                                                <p className="cardSubHeading">&nbsp;&nbsp;&nbsp;&nbsp;Qualifikationen</p>
                                                
                                                    <ul id="qualifikationen">
                                                        <li>Auditor für die Regelwerke <br /> - ISO 9001 <br /> - SCC/SCP <br /> - OHSAS 18001 <br /> - ISO 14001 <br /> - BS 8800 <br /> - DIN 77200 <br /> - DPG <br /> - SAA <br /> - ISO 50001</li>
                                                        <li>Sicherheitsingenieur</li>
                                                        <li>Befähigte Person zur Ladungssicherung (VDI 2700 ff)</li>
                                                        <li>Datenschutzbeauftragter</li>
                                                        <li>Entwurfsverfasser</li>
                                                        <li>Tragwerksplaner</li>
                                                        <li>Energieauditor ISO 50001</li>
                                                        <li>Befähigte Person für Gerüstbau</li>
                                                        <li>Betriebsbeauftragter für Abfall</li>
                                                        <li>Immissionsschutzbeauftragter</li>
                                                        <li>Mitgliedschaften in VDI, VDSI, DGQ, Ingenieurkammer</li>
                                                    </ul>
                                            </div>
                                    </div>
                            </div>

                        </div>                                   
                    </div>
                    
                </div>
            </React.Fragment>
        )

    }

}

export default Ueberuns;
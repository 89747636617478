import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {Helmet} from "react-helmet";

import Header from "./components/Header";


import Home from "./pages/Home";
import Aktuelles from "./pages/Aktuelles";
import Unternehmen from "./pages/Unternehmen";
import Geschaeftsfelder from "./pages/Geschäftsfelder";
import Ueberuns from "./pages/Ueberuns";
import Referenzen from "./pages/Referenzen";
import Impressum from "./pages/Impressum";


class App extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <link href="http://fonts.googleapis.com/css?family=Fira+Sans:500,400|Righteous|Capriola|Basic" rel="stylesheet" type="text/css" />
                </Helmet>
                <Header/>

                <Router>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/aktuelles" component={Aktuelles} />
                        <Route exact path="/unternehmen" component={Unternehmen} />

                        <Route path="/geschaeftsfelder" component={Geschaeftsfelder} />

                        <Route path="/ueberuns" component={Ueberuns} />

                        <Route path="/referenzen" component={Referenzen} />

                        <Route path="/impressum" component={Impressum} />
                    </Switch>
                </Router>
            </React.Fragment>
        )

    }

}

export default App;
import React from "react";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import HeaderImg from "../../img/siteheaders/beratung.png";
import Helmet from "react-helmet";

class Beratung extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Beratung | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Beratung"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop arbsich">Beratung des Managements zu diversen Gebieten</th></tr>   
                        <tr><td>Gesamtunternehmerische Risikobetrachtungen</td></tr>
                        <tr><td>Risiken von Projekten und deren Abwicklung</td></tr>
                        <tr><td>Erhöhung des Sicherheitsbewusstseins im Unternehmen</td></tr>
                        <tr><td>Aus Fehlern lernen</td></tr>
                        <tr><td>Unternehmensstrategien</td></tr>
                        <tr><td>Beratung und Konzeptionen bei Umstrukturierungen des Unternehmens</td></tr>
                    </tbody>
                </table>

            </React.Fragment>
        )

    }

}

export default Beratung;
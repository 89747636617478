import React from "react";
import {Link} from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";
import HeaderImg from "../../img/siteheaders/ref.png";
import Helmet from "react-helmet";


class Begutachtungen extends React.Component{

    constructor(props){
        super(props);
    }

    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Referenzen: Begutachtungen | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <div className="content">
                    <Link to="/referenzen">
                        <h1 className="overSiteHeader">Referenzen</h1>
                    </Link>
                    
                    <HeaderCard img={HeaderImg} text={"Referenzen"} />

                    <div className="widebox">
                        <h2>Ein breit gefächertes Erfahrungsspektrum, das Ihnen zu gute kommt.</h2>
                        <p>Sie finden hier eine Übersicht über unsere Tätigkeitsbereiche und gelangen über das Menü am linken Rand zu einer Auflistung der namenhaften Firmen, die schon von unseren Leistungen überzeugt sind.
                        Überzeugen Sie sich nun selbst. So viele Kunden können nicht irren!</p>
                        
                        <ul className="refList">
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup energie">Energiemanagement</p>                            
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/8.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/25.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/powertrain.png")} /></div>
                            </div>
                            </li>
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup arbsich">Sicherheitsdienstleistungen ( DIN 77200 )</p>                            
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/33.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/19.png")} /></div>
                            </div>
                            </li>
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup umwelt">Umweltschutz</p>                            
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/24.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/20.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/8.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/25.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/LW.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/powertrain.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/ske.png")} /></div>
                            </div>
                            </li>
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup quali">Qualitätsmanagement</p>  
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/14.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/4.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/2.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/23.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/17.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/22.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/12.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/32.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/24.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/10.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/20.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/19.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/dorschConsult.png")} /></div> 
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/pbr.png")} /></div> 
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/LW.png")} /></div>                            
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/baucomex.png")} /></div>                             
                            </div>
                            </li>
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup arbsich">Arbeitssicherheit</p>  
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/16.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/13.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/5.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/28.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/36.jpg")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/26.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/34.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/9.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/15.png")} /></div>                          
                            </div>
                            </li>
                            
                            <li>
                            <div className="refBox">
                                <p className="refGroup quali">QM + Arbeitssicherheit</p>  
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/11.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/21.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/31.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/18.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/37.jpg")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/35.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/29.png")} /></div>                          
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/22.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/24.png")} /></div>
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/ske.png")} /></div>  
                                <div className="imgLogo"><img src={require("../../img/firmenlogos/ghmarine.png")} /></div>                                                
                            </div>
                            </li>
                            
                            
                        </ul>
                        <p>Die oben aufgeführten Marken sind Eigentum der jeweiligen Inhaber. Sollte eine Markenzuordnung fehlerhaft sein, kontaktieren Sie uns umgehend zwecks einer Korrektur.</p>
                    </div>
                </div>
            </React.Fragment>

        );

    }

}

export default Begutachtungen;
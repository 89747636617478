import React from "react";

import HeaderCard from "../../components/HeaderCard";
import LinkCard from "../../components/LinkCard";

import HeaderImg from "../../img/siteheaders/gfelder.png";
import Helmet from "react-helmet";

class Start extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder | Ingenieurbüro Jerratsch</title>
                </Helmet>
                
                <div className="content">
                    <HeaderCard img={HeaderImg} text={"Geschäftsfelder"} />
                    
                    <p>
                        Unsere Geschäftsfelder erstrecken sich über <strong>mehr als 40 Komponenten</strong> in <strong>6 Bereichen</strong>.
                        <br/><br/>
                        <span style={{fontSize: "smaller"}}>
                            Für umfangreichere Informationen folgen Sie den hier aufgelisteten Links oder der Menüleiste am linken Rand,
                            um zu den einzelnen Unterbereichen zu gelangen.
                        </span>
                    </p>
                            
                    <ul>
                        <LinkCard link={"/geschaeftsfelder/gutachten"} text={"Begutachtung von Unternehmensabläufen"} withArrow={true} />
                        <LinkCard link={"/geschaeftsfelder/arbeitssicherheit"} text={"Betreuung bei Arbeitssicherheit"} withArrow={true} />
                        <LinkCard link={"/geschaeftsfelder/beratung"} text={"Beratung des Managements"} withArrow={true} />
                        <LinkCard link={"/geschaeftsfelder/datenschutz"} text={"Datenschutz im Unternehmen"} withArrow={true} />
                        <LinkCard link={"/geschaeftsfelder/managementsysteme"} text={"Kompetenz in Managementsystemen"} withArrow={true} />
                        <LinkCard link={"/geschaeftsfelder/schulungen"} text={"Schulungen / Seminare"} withArrow={true} />
                    </ul>
                </div>
            </React.Fragment>
        )

    }

}

export default Start;
import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import ArrowImg from "../../img/arrow.png";

const SubLink = ({link, text, withArrow, children, style, ...props}) => {
    return (
        <Link to={link}>
            {
                withArrow ?
                <React.Fragment>
                    <li style={style || {}}>
                        {children}
                        <img src={ArrowImg}/>
                    </li>
                </React.Fragment>
                :
                children
            }
        </Link>
    )
};

class SubMenu extends React.Component{

    constructor(props){
        super(props);
    }


    render(){

        return (
            <div className="subMenu">
                <ul>
                    <SubLink link={"/geschaeftsfelder/gutachten"} withArrow={true}>
                        Gutachten
                    </SubLink>
                    <SubLink link={"/geschaeftsfelder/arbeitssicherheit"} withArrow={true}>
                        Arbeitssicherheit
                    </SubLink>
                    <SubLink link={"/geschaeftsfelder/beratung"} withArrow={true}>
                        Beratung
                    </SubLink>
                    <SubLink
                    link={"/geschaeftsfelder/grossprojekte"}
                    withArrow={true}
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                    }}
                    >
                        Rechtssichere Arbeitssicherheit bei Großprojekten
                    </SubLink>
                    <SubLink link={"/geschaeftsfelder/managementsysteme"} withArrow={true}>
                        Managementsysteme
                    </SubLink>
                    <ul>
                        <SubLink link={"/geschaeftsfelder/managementsysteme#Umwelt"} withArrow={false}>
                            <li className="umwelt">Umwelt</li>
                        </SubLink>
                        <SubLink link={"/geschaeftsfelder/managementsysteme#Energie"} withArrow={false}>
                            <li className="energie">Energie</li>
                        </SubLink>
                        <SubLink link={"/geschaeftsfelder/managementsysteme#Arbeitssicherheit"} withArrow={false}>
                            <li className="arbsich">Arbeitssicherheit</li>
                        </SubLink>
                        <SubLink link={"/geschaeftsfelder/managementsysteme#QM"} withArrow={false}>
                            <li className="quali">Qualität</li>
                        </SubLink>
                        <SubLink link={"/geschaeftsfelder/managementsysteme#ExcelentService"} withArrow={false}>
                            <li className="exservice">Excelent Service</li>
                        </SubLink>
                    </ul>
                    <SubLink link={"/geschaeftsfelder/schulungen"} withArrow={true}>
                        Schulungen
                    </SubLink>
                </ul>
            </div>
        );

    }

}

export default SubMenu;
import React from "react";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import HeaderImg from "../../img/siteheaders/beratung.png";
import Helmet from "react-helmet";

class Arbeitssicherheit extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Arbeitssicherheit | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Arbeitssicherheit"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop arbsich">Betreuung zur Arbeitssicherheit in den Bereichen</th></tr>                        
                        <tr><td>Gestellung einer Fachkraft für Arbeitssicherheit</td></tr>
                        <tr><td>Sicherheitsbegehungen</td></tr>
                        <tr><td>Schulungen zur Arbeitssicherheit</td></tr>
                        <tr><td>Gefährdungsanalysen</td></tr>
                        <tr><td>SiGe-Koordination</td></tr>
                        <tr><td>interne Sicherheitsaudits</td></tr>
                    </tbody>
                </table>

            </React.Fragment>
        )

    }

}

export default Arbeitssicherheit;
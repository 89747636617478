import React from "react";
import ReactDOM from "react-dom";

import "./styles/main.css";
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

import App from "./App";

const rootElement = document.getElementById("root");

if(rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<App />, rootElement);
}
else {
    ReactDOM.render(<App />, rootElement);
}
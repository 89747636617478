import React from "react";
import LinkCard from "../../components/LinkCard";

import HeaderImg from "../../img/siteheaders/kontakt.png";
import HeaderCard from "../../components/HeaderCard";
import Helmet from "react-helmet";

class Impressum extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Impressum / Kontakt | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <div className="mainWrapper center">
                    <div className="maincard standAlone">                	                       			
                        <div className="content">
                            <HeaderCard img={HeaderImg} text={"Impressum / Kontakt"} />                            
                                
                            <table style={{
                                left: "0px",
                                right: "0px",
                                margin: "10px auto",
                                width: "290px",
                                borderRadius: "3px",
                                boxShadow: "1px 0 4px #aaa",
                                padding: "30px",
                                marginBottom: "20px"
                            }}>
                                <tbody>
                                    <tr><td colspan="2"><strong>Ingenieur-Büro D. Jerratsch</strong></td></tr>
                                    <tr><td colspan="2">Bramwaldstraße 83</td></tr>
                                    <tr><td colspan="2">37081 Göttingen</td></tr>
                                    <tr><td colspan="2">&nbsp;</td></tr>
                                    <tr><td>Telefon:</td><td>0551 - 95266</td></tr>
                                    <tr><td>Telefax:</td><td>0551 - 96745</td></tr>
                                    <tr><td>Mobil:</td><td>0177 - 6695266</td></tr>
                                    <tr><td colspan="2">&nbsp;</td></tr>
                                    <tr><td>Ust-IdNr.:</td><td>DE134720479</td></tr>
                                    <tr><td>Steuer-Nr.</td><td>2012009039</td></tr>
                                </tbody>
                            </table>
                            
                            <br/>
                            
                            <div className="widecard">
                                <p className="cardHeading">Kontakt</p>
                                
                                <p style={{
                                    fontSize: "14px"
                                }}>
                                    <br/><br/>
                                    <b>Sie haben eine Frage an uns?</b>
                                    <br/><br/>
                                    Dann kontaktieren Sie uns bitte unter folgender Emailadresse: <br/> 
                                    <a href="mailto:detlef.jerratsch@t-online.de">detlef.jerratsch@t-online.de</a>
                                    <br/><br/>
                                    Oder telefonisch unter:
                                    <br/>0551 - 95266
                                    <br/><br/><br/><br/>
                                </p>
                            
                            </div>

                            <br/>

                            <div className="widecard">
                                <p className="cardHeading">Haftungsausschluss</p>
                                
                                <p style={{
                                    padding: "10px",
                                    fontSize: "14px"
                                }}>
                                    Alle Informationen und Inhalte dienen ausschließlich der persönlichen Information des Besuchers.
                                    Alle Angaben und Darstellungen wurden mit großer Sorgfalt zusammengestellt und dürfen nur für den privaten Gebrauch vervielfältigt werden.
                                    Es dürfen keine Änderungen vorgenommen und Kopien zur öffentlichen Wiedergabe benutzt werden.
                                    Sämtliche Informationen auf den Seiten erfolgen ohne Gewähr für die Richtigkeit.
                                    Wir übernehmen keine Haftung für Schäden, die sich aus der Verwendung der abgerufenen Informationen ergeben. Irrtümer und Änderungen bleiben vorbehalten.
                                </p>
                            </div>

                            <div className="widecard">
                                <p className="cardHeading">Datenschutz</p>
                                
                                <p style={{
                                    padding: "10px",
                                    fontSize: "14px",
                                    textAlign: "justify"
                                }}>
                                    Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst.<br/>
                                    Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                                    <br/>
                                    <br/>
                                    Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich.
                                    Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies stets auf freiwilliger Basis.
                                    Diese Daten werden nicht an Dritte weitergegeben.
                                    <br/>
                                    <br/>
                                    
                                    <b>Arten der verarbeiteten Daten</b>
                                    <br/>
                                    - Kontaktdaten (z.B. E-Mail).
                                    <br/>
                                    - Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).

                                    <br/>
                                    <br/>
                                    <b>Kategorien betroffener Personen</b>
                                    <br/>
                                    Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
                                    
                                    <br/>
                                    <br/>
                                    <b>Zweck der Verarbeitung</b>
                                    <br/>
                                    - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
                                    <br/>
                                    - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.

                                    <br/>
                                    <br/>
                                    <b>Widerrufsrecht</b>
                                    <br/>
                                    Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.


                                    <br/>
                                    <br/>
                                    <b>Widerspruchsrecht</b>
                                    <br/>
                                    Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen.
                                    Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.

                                    <br/>
                                    <br/>
                                    <b>Kontaktaufnahme</b>
                                    <br/>
                                    Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation gespeichert werden.
                                    Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.

                                    <br/>
                                    <br/>
                                    <b>Hosting</b>
                                    <br/>
                                    Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.
                                    <br/>
                                    Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).

                                    <br/>
                                    <br/>
                                    <b>Nutzung von Google Web Fonts</b>
                                    <br/>
                                    Diese Internetseite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts, die von Google bereitgestellt werden.<br/>
                                    Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.<br/>
                                    Sollte Ihr Browser Web Fonts nicht unterstützen, wird eine Standardschrift von Ihrem Computer genutzt. 
                                    Dies kann das Erscheinungsbild unserer Internetseite verschlechtern.<br/>
                                    Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" rel="nofollow" target="_blank">https://developers.google.com/fonts/faq</a>  und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" rel="nofollow" target="_blank">https://www.google.com/policies/privacy/</a>

                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    Vom Websiteinhaber angepasst,
                                    <br/>
                                    <a href="https://datenschutz-generator.de/" rel="nofollow noopener" target="_blank">erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
                                </p>
                            </div>

                        </div>                                   
                    </div>
                    
                </div>
            </React.Fragment>
        )

    }

}

export default Impressum;
import React from "react";
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import SubMenu from "./submenu";

import Start from "./Start";
import Gutachten from "./Gutachten";
import Arbeitssicherheit from "./Arbeitssicherheit";
import Beratung from "./Beratung";
import Rechtssicher from "./Rechtssicher";
import Managementsysteme from "./Managementsysteme";
import Schulungen from "./Schulungen";

class Page extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        const match = this.props.match;

        return (
            <div className="mainWrapper center">

                <SubMenu/>

                <div className="maincard">
                    
                    <Switch>
                        <Route path={`${match.url}/gutachten`}>
                            <Gutachten/>
                        </Route>

                        <Route path={`${match.url}/arbeitssicherheit`}>
                            <Arbeitssicherheit/>
                        </Route>

                        <Route path={`${match.url}/beratung`}>
                            <Beratung/>
                        </Route>

                        <Route path={`${match.url}/grossprojekte`}>
                            <Rechtssicher/>
                        </Route>

                        <Route path={`${match.url}/managementsysteme`}>
                            <Managementsysteme/>
                        </Route>

                        <Route path={`${match.url}/schulungen`}>
                            <Schulungen/>
                        </Route>


                        <Route>
                            <Start/>
                        </Route>
                    </Switch>

                </div>
                
            </div>
        )

    }

}

export default withRouter(Page);
import React from "react";
import { Link } from "react-router-dom";

import ArrowImg from "../../img/arrow.png";

class LinkCard extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <li className="linkCard">
                <Link to={this.props.link}>
                    {this.props.text}
                    {
                        this.props.withArrow ?
                        <img src={ArrowImg} />
                        :
                        null
                    }
                </Link>
            </li>
        )

    }

}

export default LinkCard;
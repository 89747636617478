import React from "react";
import { Link } from "react-router-dom";
import HeaderCard from "../../components/HeaderCard";

import HeaderImg from "../../img/siteheaders/schulungen.png";
import Helmet from "react-helmet";

class Schulungen extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Geschäftsfelder: Schulungen | Ingenieurbüro Jerratsch</title>
                </Helmet>

                <Link to="/geschaeftsfelder">
                    <h1 className="overSiteHeader">Geschäftsfelder</h1>
                </Link>
                
                <HeaderCard img={HeaderImg} text={"Schulungen"} />

                <table id="refUbersicht">
                    <tbody>
                        <tr><th className="tableTop">Schulungen - Seminare - Workshops</th></tr>                        
                        <tr><td>Erstellung von Gefährdungsbeurteilungen</td></tr>
                        <tr><td>SGU-Schulung für operative Mitarbeiter gemäß SCC-DoK. 016</td></tr>
                        <tr><td>SGU-Prüfung von operativen Mitarbeitern gemäß SCC-DoK. 017</td></tr>
                        <tr><th>Schulungen</th></tr>
                        <tr><td>Schulung "Anschlagen von Lasten"</td></tr>
                        <tr><td>Schulung "Gefahrstoffverordnung"</td></tr>
                        <tr><td>Schulung "Umweltschutz-Managementsystem"</td></tr> 
                        <tr><th>Workshops</th></tr>
                        <tr><td>Workshop "Arbeitssicherheit in der Angebotsphase"</td></tr>
                        <tr><td>Workshop "Baustellenkreissäge, Gerüste und Leitern</td></tr>
                        <tr><td>Workshop "Arbeitssicherheits-Management"</td></tr>
                    </tbody>
                </table>
                
            </React.Fragment>
        )

    }

}

export default Schulungen;
import React from "react";
import {Helmet} from "react-helmet";

import HeaderImg from "../../img/siteheaders/start.png";
import HeaderCard from "../../components/HeaderCard";

class AktuellesPage extends React.Component{

    constructor(props){
        super(props)
    }


    render(){

        return (
            <React.Fragment>
                <Helmet>
                    <title>Aktuelles | Ingenieurbüro Jerratsch</title>
                </Helmet>
                <div className="mainWrapper center">    
                    <div className="maincard standAlone">                	                       			
                        <div className="content">
                            <HeaderCard img={HeaderImg} text={"Aktuelles"} />
                            <ul>
                            
                                <li>
                                    <div className="widecard">    
                                        <p
                                        className="cardHeading"
                                        style={{fontSize: "18px"}}
                                        >
                                            Workshop:
                                            <br/>
                                            <br/>
                                            Planung einer (rechts-)sicheren (unfallfreien) Baustelle
                                        </p>
                                        <div style={{height: "100px", padding: "20px 10px"}}>
                                            <p>
                                                Dabei handelt es sich um Themen der Arbeitssicherheit, von der Akquise über Angebotsbearbeitung, AV,
                                                Beschaffung und Ausführung vor Ort.
                                            </p>
                                        </div>
                                    </div>

                                    <br/>
                                    <br/>

                                    <div className="widecard">
                                        <p
                                        className="cardHeading"
                                        style={{fontSize: "18px"}}
                                        >
                                            Beratung zur Notfallplanung und Durchführung von Notfallübungen bei Großprojekten
                                        </p>
                                        <div style={{height: "100px", padding: "20px 10px"}}>
                                            <p>
                                                Vermittlung der Grundlagen der Notfallplanung, Inhalte eines Notfallplanes, sowie Erstellung einer Notfalldokumentation
                                            </p>
                                        </div>
                                    </div>
                                </li>               
                            </ul>
                        </div>                                   
                    </div>
                    
                </div>
            </React.Fragment>
        )

    }

}

export default AktuellesPage;
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";
import SubMenu from "./submenu";

import Start from "./Start";
import Beratung from "./Beratung";
import Begutachtungen from "./Begutachtungen";

class Referenzen extends React.Component{

    constructor(props){
        super(props)
    }


    render(){
        const match = this.props.match;

        return (
            <div className="mainWrapper center">

                <SubMenu/>

                <div className="maincard">

                   
                            
                    <Switch>
                        <Route path={`${match.url}/beratung`}>
                            <Beratung/>
                        </Route>

                        <Route path={`${match.url}/begutachtungen`}>
                            <Begutachtungen/>
                        </Route>


                        <Route>
                            <Start/>
                        </Route>
                    </Switch>
                              
                </div>
                
            </div>
        )

    }

}

export default Referenzen;